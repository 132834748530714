import React from 'react'
import PropTypes from 'prop-types'
import styles from './Section1.module.scss'
import { Fade } from 'react-reveal'

const Section1 = props => {
  const { className, children, id } = props
  return (<div id={id} className={[className, styles.container].join(' ')}>
    <div className={['row text-center', styles.centered].join(' ')}>
      <div className='col-md-12'>
        <Fade>
          {children}
        </Fade>
      </div>
      <div className='container'>
        <div className='row'>
          <div className='col-md-12'>
            <div className="table-responsive">
              <table className="table">
                <thead className="thead-dark">
                  <tr>
                    <th scope="col">Product</th>
                    <th scope="col">Price</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">1</th>
                    <td>Mark</td>
                  </tr>
                  <tr>
                    <th scope="row">2</th>
                    <td>Jacob</td>
                  </tr>
                  <tr>
                    <th scope="row">3</th>
                    <td>Larry</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="table-responsive">
              <table className="table">
                <thead className="thead-dark">
                  <tr>
                    <th scope="col">Product</th>
                    <th scope="col">Price</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">1</th>
                    <td>Mark</td>
                  </tr>
                  <tr>
                    <th scope="row">2</th>
                    <td>Jacob</td>
                  </tr>
                  <tr>
                    <th scope="row">3</th>
                    <td>Larry</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>)
}

Section1.propTypes = {
  className: PropTypes.any,
  children: PropTypes.any,
  title: PropTypes.any,
  id: PropTypes.string
}

export default Section1
